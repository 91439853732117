import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

class FeaturesSplit extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-split section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'features-split-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const splitClasses = classNames(
      'split-wrap',
      invertMobile && 'invert-mobile',
      invertDesktop && 'invert-desktop',
      alignTop && 'align-top'
    );

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            {/* <SectionHeader data={sectionHeader} className="center-content reveal-from-top" /> */}
            <div className={splitClasses}>

              <div className="split-item about-item">
                <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                  <h3 className="mt-0 mb-16">
                    Top Outsourced Service
                  </h3>
                  <p className="m-0">
                    With extensive experience, coupled with impressive business credentials, Libeccio Solutions provides a full spectrum of services to the needs of Gaming industry: infrastructure design-and-build services; systems integration and consulting services; and business process outsourcing services.  
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-rotate-from-left',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item">
                  <Image src={require('./../../assets/images/feature_1.png')} alt="Features split top 01" />
                </div>
              </div>

              <div className="split-item about-item">
                <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                  <h3 className="mt-0 mb-16">
                    Gaming Platform
                  </h3>
                  <p className="m-0">Gear up for the next-gen gaming experience.</p>
                  <p className="m-0">Libeccio integrates game gallery, account system, community and all operations for the convenience of players.</p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-rotate-from-right',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item">
                    <Image src={require('./../../assets/images/feature_2.png')} alt="Features split top 02" />
                </div>
              </div>

              <div className="split-item about-item">
                <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                  <h3 className="mt-0 mb-16">
                    Developer Friendly
                  </h3>
                  <p className="m-0">
                    Easy transition into game developers.
                  </p>
                  <p className="m-0">
                    At Libeccio game developers are provided with GaaS (Game as a Service), eco fund and promotion support, so they can concentrate on making high-quality games.
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-rotate-from-left',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item">
                  <Image src={require('./../../assets/images/feature_3.png')} alt="Features split top 03" />
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    );
  }
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;