import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Image from '../../elements/Image';

const Logo = ({
  className,
  lightMode,
  ...props
}) => {

  const classes = classNames(
    'brand',
    className
  );

  return (
    <div
      {...props}
      className={classes}
    >
      <h1 className="m-0">
        <Link to="/">
          { lightMode &&
            <Image
              src= {require('./../../../assets/images/logo.png')}
              alt="Blue"
              width={132}
              height={32} />
          }
          { !lightMode &&
            <Image
              src= {require('./../../../assets/images/logo.png')}
              alt="Blue"
              width={132}
              height={32} />
          }
        </Link>
      </h1>
    </div>
  );
}

export default Logo;