import React from 'react';
import HeroSplit from '../components/sections/HeroSplit';
import Clients from '../components/sections/Clients';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Team from '../components/sections/Team';
import Roadmap from '../components/sections/Roadmap';
import Testimonial from '../components/sections/Testimonial';
import ScrollableAnchor from 'react-scrollable-anchor';
class Home extends React.Component {
  render() {
    return (
      <React.Fragment>
        <HeroSplit invertMobile imageFill className="illustration-section-01" />

        <ScrollableAnchor id={'About'}>
          <FeaturesSplit invertMobile imageFill />
        </ScrollableAnchor>

        {/* <ScrollableAnchor id={'Games'}>
          <Testimonial hasBgColor invertColor/>
        </ScrollableAnchor> */}

        {/* <FeaturesTiles /> */}

        {/* <Cta topDivider bottomDivider split className="reveal-from-top" /> */}
        <div className="spacer-48-mobile"></div>
      </React.Fragment>
    );
  }
}

export default Home;