import React from 'react';
import classNames from 'classnames';

const FooterSocial = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'footer-social',
    className
  );

  return (
    <div
      {...props}
      className={classes}
    >
      <a className='social-item' href="https://discord.com/"><i className='iconfont icon-discord'></i></a>
      <a className='social-item' href="https://twitter.com/"><i className='iconfont icon-twitter'></i></a>
      <a className='social-item' href="https://telegram.com/"><i className='iconfont icon-telegram'></i></a>
      <a className='social-item' href="https://google.com/"><i className='iconfont icon-reddit'></i></a>
      <a className='social-item' href="https://google.com/"><i className='iconfont icon-instagram'></i></a>
    </div>
  );
}

export default FooterSocial;