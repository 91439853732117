import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import GameImage1 from './../../assets/images/game_01.png';
import GameImage2 from './../../assets/images/game_02.png';
import GameImage3 from './../../assets/images/game_03.png';
import GameImage4 from './../../assets/images/game_04.png';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

class Testimonial extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      imgHover: 0,
    };
  }

  handleHover(i) {
    this.setState({imgHover: i});
  }

  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'testimonial section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'testimonial-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap game-items',
      pushLeft && 'push-left'
    );

    const sectionHeader = {
      title: 'Enjoy The Game',
      paragraph: ''
    };

    const getImgBgStyle = (i, url) => {
      return { 
        backgroundImage: this.state.imgHover===i ? `url(${url})` : `url(${url})`,
	      backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundColor: 'transparent',
        backgroundBlendMode: 'overlay',
        minHeight: 322,
        padding: this.state.imgHover===i ? `14px 0 0 62px` : `24px 34px`,
      };
    }

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <div className='paint1'></div>
            <div className='paint2'></div>
            <div className='paint3'></div>
            <div className='paint4'></div>
            <div className='paint5'></div>
            <div className='paint6'></div>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={tilesClasses}>
              <div className="game-item tiles-item">
                <div className="tiles-item-inner" 
                  style={getImgBgStyle(1, GameImage1)}
                  onMouseEnter={()=>this.handleHover(1)}
                  onMouseLeave={()=>this.handleHover(0)}
                  >
                  <div className="testimonial-item-footer h4 mt-0 mb-12">
                    <span className="testimonial-item-name">
                      <a href="#0">Age of Dino</a>
                    </span>
                  </div>
                  {(this.state.imgHover===1) &&
                  <div className="testimonial-item-content">
                    <p className="text-sm mb-16 text-mask-light">
                    A dinosaur-themed mobile MMO strategy game with amazing entertainment features and a play-to-earn model.
                    </p>
                  </div>
                  }
                  <a ref="testt" href='#0' className='button goto-btn'>COMING SOON</a>
                </div>
              </div>

              <div className="game-item tiles-item" data-reveal-delay="200">
                <div className="tiles-item-inner" 
                  style={getImgBgStyle(2, GameImage2)}
                  onMouseEnter={()=>this.handleHover(2)}
                  onMouseLeave={()=>this.handleHover(0)}>
                  <div className="testimonial-item-footer h4 mt-0 mb-12">
                    <span className="testimonial-item-name">
                      <a href="#0">Dino<br />Continent</a>
                    </span>
                  </div>
                  {(this.state.imgHover===2) &&
                  <div className="testimonial-item-content">
                    <p className="text-sm mb-16 text-mask-light">
                    An open-world simulation game. It is also part of the dinosaur-themed Metaverse with shared background and interoperable hero. 
                    </p>
                  </div>
                  }
                  <a ref="testt" href='#0' className='button goto-btn'>COMING SOON</a>
                </div>
              </div>

              <div className="game-item tiles-item" data-reveal-delay="400">
                <div className="tiles-item-inner" 
                  style={getImgBgStyle(3, GameImage3)}
                  onMouseEnter={()=>this.handleHover(3)}
                  onMouseLeave={()=>this.handleHover(0)}>
                  <div className="testimonial-item-footer h4 mt-0 mb-12">
                    <span className="testimonial-item-name">
                      <a href="#0">Meta<br />MechaWar</a>
                    </span>
                  </div>
                  {(this.state.imgHover===3) &&
                  <div className="testimonial-item-content">
                    <p className="text-sm mb-16 text-mask-light">
                    Fusionist is a space-themed game consisting of three interconnected game stages built by gaming veterans using the Unity engine.
                    </p>
                  </div>
                  }
                  <a ref="testt" href='#0' className='button goto-btn'>COMING SOON</a>
                </div>
              </div>

              <div className="game-item tiles-item">
                <div className="tiles-item-inner"
                  style={getImgBgStyle(4, GameImage4)}
                  onMouseEnter={()=>this.handleHover(4)}
                  onMouseLeave={()=>this.handleHover(0)}>
                  <div className="testimonial-item-footer h4 mt-0 mb-12">
                    <span className="testimonial-item-name">
                      <a href="#0">Fusionist</a>
                    </span>
                  </div>
                  {(this.state.imgHover===4) &&
                  <div className="testimonial-item-content">
                    <p className="text-sm mb-16 text-mask-light">
                    MetaMechaWar is a mecha-themed card RPG game with PVP and PVE gameplay. It is the first real-time strategic “Mechaverse War Conquest Game”. 
                    </p>
                  </div>
                  }
                  <a ref="testt" href='#0' className='button goto-btn'>COMING SOON</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;